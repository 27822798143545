import { createSlice } from "@reduxjs/toolkit";

export interface IMasterState {
  master: any;
  loader: boolean;
  notificationCount: number;
  history: string[];
}

const initialState: IMasterState = {
  master: {},
  loader: false,
  notificationCount: 0,
  history: [],
};

const MasterSlice = createSlice({
  name: "master",
  initialState,
  reducers: {
    getMasterData: (state, action) => ({
      ...state,
      master: {
        ...state.master,
        ...action.payload,
      },
    }),
    setLoader: (state, action) => ({
      ...state,
      loader: action.payload,
    }),
    setNotificationCount: (state, action) => ({
      ...state,
      notificationCount: action.payload,
    }),
    setHistoryRoute: (state, action) => ({
      ...state,
      history: [...state.history, action.payload],
    }),
  },
});

export const { getMasterData, setLoader, setNotificationCount, setHistoryRoute } =
  MasterSlice.actions;
export default MasterSlice;
