/* eslint-disable @typescript-eslint/no-shadow */
export enum ROLE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ROLE_SHORT_CODE {
  SUPER_ADMIN = "SA",
  SUPER_EMPLOYEE = "SE",
  OEM_ADMIN = "OEMA",
  OEM_EMPLOYEE = "OEME",
  DRIVER = "DR",
  EV_OWNER = "EVO",
  FLEET_ADMIN = "FA",
  FLEET_EMPLOYEE = "FE",
  DEALER_ADMIN = "DA",
  DEALER_EMPLOYEE = "DE",
  BMS_ADMIN = "BMSA",
  BMS_EMPLOYEE = "BMSE",
  MCU_ADMIN = "MCUA",
  MCU_EMPLOYEE = "MCUE",
  VCU_ADMIN = "VCUA",
  VCU_EMPLOYEE = "VCUE",
  GPS_ADMIN = "GPSA",
  GPS_EMPLOYEE = "GPSE",
  OBC_ADMIN = "OBCA",
  OBC_EMPLOYEE = "OBCE",
  DCDC_ADMIN = "DCDCA",
  DCDC_EMPLOYEE = "DCDCE",
  BTMS_ADMIN = "BTMSA",
  BTMS_EMPLOYEE = "BTMSE",
  TPMS_ADMIN = "TPMSA",
  TPMS_EMPLOYEE = "TPMSE",
}

export const vehicleTypeObj: Record<string, any> = {
  "2 Wheeler": "2 Wheeler",
  "3 Wheeler": "3 Wheeler",
};

export const connectivityObj: Record<string, any> = {
  "Wi-Fi": "Wi-Fi",
  GSM: "GSM",
  "3G": "3G",
  "4G": "4G",
  "5G": "5G",
};

export enum TICKET_STATUS {
  CLOSED = 6,
  NEW = 1,
  IN_PROGRESS = 2,
  HOLD = 3,
  REOPEN = 4,
  DELETED = 5,
}

export const ticketStatus: Record<string, number> = {
  Closed: 6,
  New: 1,
  "In-Progress": 2,
  Hold: 3,
  Reopen: 4,
  Deleted: 5,
};

export enum VEHICLE_STATE {
  STANDBY = 0,
  ACCESSORY = 16, // key on status
  STARTUP = 32,
  HVON = 48, // tern key and brk but not start
  DRIVE_START_UP = 64,
  DRIVE = 66,
  LIMP_HOME = 67, // reduce driving performance
  CHARGE_START_UP = 80,
  AC_CHARGE = 81,
  DC_CHARGE = 82,
  SHUTDOWN = 96,
  EMERGENCY_SHUTDOWN = 101,
  DISCHARGE = 112,
  SNA = "F", // signal not available
}

export const vehicleState: Record<string, any> = {
  StandBy: 0,
  Accessory: 16, // key on status
  StartUp: 32,
  HVON: 48, // tern key and brk but not start
  "Drive Start Up": 64,
  Drive: 66,
  "Limp Home": 67, // reduce driving performance
  "Charge Start Up": 80,
  "AC Charge": 81,
  "DC Charge": 82,
  Shutdown: 96,
  "Emergency Shutdown": 101,
  Discharge: 112,
  SNA: "F", // signal not available
};

export const BMSState: Record<string, any> = {
  Off: 0,
  Ready: 1,
  Drive: 2,
  Charge: 3,
  Emergency: 4,
  Fault: 5,
  SNA: "F",
};

export const MCUState: Record<string, any> = {
  Off: 0,
  Ready: 1,
  Drive: 2,
  LimpHome: 3,
  Discharge: 4,
  Emergency: 5,
  Fault: 6,
  SNA: "F",
};

export enum FAULT_SEVERITY {
  NONE = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  CRITICAL = 4,
  EMERGENCY = 5,
  SNA = "F", // signal not available
}

export const faultComponents: Record<string, string> = {
  BMS: "BMS",
  VCU: "VCU",
  MCU: "MCU",
  EV: "EV",
};

export const faultTypes: Record<string, string> = {
  Component: "Component",
  System: "System",
};

export const faultCategories: Record<string, string> = {
  Alert: "Alert",
  Fault: "Fault",
};

export enum DRIVE_MODE {
  NORMAL = 0,
  ECONOMY = 1,
  PERFORMANCE = 2,
  SNA = "F",
}

export const driveMode: Record<string, any> = {
  Normal: 0,
  Economy: 1,
  Performance: 2,
  SNA: "F",
};

export enum PRND {
  PARK = 0,
  NEUTRAL = 1,
  DRIVE = 2,
  REVERSE = 3,
  SNA = "F",
}

export enum BMS {
  OFF = 0,
  READY = 1,
  DRIVE = 2,
  CHARGE = 3,
  EMERGENCY = 4,
  FAULT = 5,
  SNA = "F",
}

export enum BATTERY_CHEMISTRY {
  NMC = 0,
  LFP = 1,
  NAAIR = 2,
  SOLID_STATE = 3,
  OTHER = 4,
  SNA = "F",
}

export const BatteryChemistry: Record<string, any> = {
  NMC: 0,
  LFP: 1,
  NAAIR: 2,
  "Solid State": 3,
  Other: 4,
  SNA: "F",
};

export enum BATTERY_THERMAL_MANAGEMENT {
  NONE = 0,
  NORMAL_AIR = 1,
  FORCED_AIR = 2,
  LIQUID_COOLED = 3,
  LIQUID_COOLED_WHEAT = 4,
  REFRIGERANT = 5,
  SUBMERGED = 6,
  OTHER = 7,
  SNA = "F",
}

export const batteryThermalManagement: Record<string, any> = {
  None: 0,
  "Normal Air": 1,
  "Forced Air": 2,
  "Liquid Cooled": 3,
  "Liquid Cooled Wheat": 4,
  Refrigerant: 5,
  Submerged: 6,
  Other: 7,
  SNA: "F",
};

export enum MCU_STATE {
  OFF = 0,
  READY = 1,
  DRIVE = 2,
  LIMP_HOME = 3,
  DISCHARGE = 4,
  REFRIGERANT = 5,
  EMERGENCY = 6,
  FAULT = 7,
  SNA = "F",
}

export enum E_MACHINE_TYPE {
  PSM = 0,
  INDUCTION = 1,
  BLDC = 2,
  BRUSHED = 3,
  SRM = 4,
  OTHER = 5,
  SNA = "F",
}

export const machineType: Record<string, any> = {
  PSM: 0,
  Induction: 1,
  BLDC: 2,
  Brushed: 3,
  SRM: 4,
  Other: 5,
  SNA: "F",
};

export const overAllCondition: Record<string, any> = {
  0: "Good Condition",
  1: "Good Condition",
  2: "Moderate",
  3: "Moderate",
  4: "Bad Condition",
  5: "Bad Condition",
  6: "Bad Condition",
  7: "Bad Condition",
};

export const faultLevel: Record<string, any> = {
  Medium: 1,
  High: 2,
  Low: 3,
  Critical: 4,
};

export enum faultSeverityStrings {
  MEDIUM = "Medium",
  HIGH = "High",
  LOW = "Low",
  CRITICAL = "Critical",
}

export enum VEHICLE_ASSIGN_VALUE_TYPE {
  DEALER = "dealer",
  FLEET = "fleet",
  EVOWNER = "evowner",
  DRIVER = "driver",
}

export const severityObj: Record<string, number> = {
  "Very Good": 1,
  Good: 2,
  Average: 3,
  Bad: 4,
  Worst: 5,
};

export enum RULE_TYPE {
  TELEMETRY = 1,
  SHADOW = 2,
  SYSTEM_GENERATED = 3,
  AI = 4,
}

export const ruleTypeObj: Record<string, number> = {
  Telemetry: 1,
  Shadow: 2,
  "System Generated": 3,
  AI: 4,
};

export enum RULE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  DELETED = 2,
}

export const ruleStatusObj: Record<string, number> = {
  Inactive: 0,
  Active: 1,
  Deleted: 2,
};

export enum CONDITION_TYPE {
  AND = 1,
  OR = 2,
}

export enum APPLIES_ON {
  OEM = 1,
  MODEL = 2,
  DEVICE = 3,
}

export enum DELIVERY_METHOD {
  PUSH_NOTIFICATION = 1,
  EMAIL = 2,
  SMS = 4,
  HOOK = 8,
  MQTT_ALERT = 16,
  COMMAND = 32,
}

export enum SEVERTY {
  VERY_GOOD = 1,
  GOOD = 2,
  AVERAGE = 3,
  BAD = 4,
  WORST = 5,
}

export enum RULE_COMPONENT_TYPE {
  VCU = 1,
  BMS = 2,
  MCU = 4,
}

export const ruleComponentType = {
  VCU: 1,
  BMS: 2,
  MCU: 4,
};

export enum ACK_STATUS {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export enum COMMAND_TYPES {
  DEVICE = 1,
  OTA = 2,
  SYSTEM = 3,
  COMPONENT = 1,
}

export enum COMMAND_FOR_TYPES {
  DEVICE_COMMAND = "DEVICE_COMMAND",
  RULE_COMMAND = "RULE_COMMAND",
  OTA_COMMAND = "OTA_COMMAND",
}

export enum MODEL_CONFIG_TABS {
  INFORMATION = "MODEL_INFORMATION",
  MASTER_DATA = "MODEL_MASTER_DATA",
  TELEMETRY = "MODEL_TELEMETRY",
  CONFIGURATION = "MODEL_CONFIGURATION",
  COMMANDS = "MODEL_COMMANDS",
  FAULTS = "MODEL_FAULTS",
}

export enum MODEL_MASTER_DATA_TABS {
  BMS_STATE = "BMS_STATE",
  MCU_STATE = "MCU_STATE",
  VCU_VEHICLE_STATE = "VCU_VEHICLE_STATE",
  VCU_FAULT_SEVERITY = "VCU_FAULT_SEVERITY",
  VCU_DRIVE_MODE = "VCU_DRIVE_MODE",
}

export enum MODEL_COMPONENT_TYPES {
  BMS = "bms",
  VCU = "vcu",
  MCU = "mcu",
  EV = "ev",
}

export enum MODEL_CONFIGURATION_STEP_ID {
  INFORMATION = 1,
  VCU_VEHICLE_STATE = 2.1,
  VCU_FAULT_SEVERITY = 2.2,
  VCU_DRIVE_MODE = 2.3,
  BMS_STATE = 3.1,
  MCU_STATE = 4.1,
  VEHICLE_FAULT_SEVERITY = 5.1,
  COMMANDS = 6,
  FAULTS = 7,
  CONFIGURATION = 8,
  TELEMETRY = 9,
}

export const OEM_TARGET_ROLE: Record<string, any> = {
  ev: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "BMS Admin", value: ROLE_SHORT_CODE.BMS_ADMIN },
    { label: "MCU Admin", value: ROLE_SHORT_CODE.MCU_ADMIN },
    { label: "VCU Admin", value: ROLE_SHORT_CODE.VCU_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
  bms: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "BMS Admin", value: ROLE_SHORT_CODE.BMS_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
  mcu: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "MCU Admin", value: ROLE_SHORT_CODE.MCU_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
  vcu: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "VCU Admin", value: ROLE_SHORT_CODE.VCU_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
};

export const COMPONENT_TARGET_ROLE: Record<string, any> = {
  bms: [{ label: "BMS Admin", value: ROLE_SHORT_CODE.BMS_ADMIN }],
  mcu: [{ label: "MCU Admin", value: ROLE_SHORT_CODE.MCU_ADMIN }],
  vcu: [{ label: "VCU Admin", value: ROLE_SHORT_CODE.VCU_ADMIN }],
};

export enum NOTIFICATION_SEVERTY {
  LOW = 3,
  MEDIUM = 1,
  HIGH = 2,
  CRITICAL = 4,
}

export const ROLE_NAMES: Record<string, any> = {
  [ROLE_SHORT_CODE.SUPER_ADMIN]: "Super Admin",
  [ROLE_SHORT_CODE.SUPER_EMPLOYEE]: "Super Employee",
  [ROLE_SHORT_CODE.OEM_ADMIN]: "OEM Admin",
  [ROLE_SHORT_CODE.OEM_EMPLOYEE]: "OEM Employee",
  [ROLE_SHORT_CODE.DRIVER]: "Driver",
  [ROLE_SHORT_CODE.EV_OWNER]: "EV Owner",
  [ROLE_SHORT_CODE.FLEET_ADMIN]: "Fleet Admin",
  [ROLE_SHORT_CODE.FLEET_EMPLOYEE]: "Fleet Employee",
  [ROLE_SHORT_CODE.DEALER_ADMIN]: "Service Center Admin",
  [ROLE_SHORT_CODE.DEALER_EMPLOYEE]: "Service Center Employee",
  [ROLE_SHORT_CODE.BMS_ADMIN]: "BMS Admin",
  [ROLE_SHORT_CODE.BMS_EMPLOYEE]: "BMS Employee",
  [ROLE_SHORT_CODE.MCU_ADMIN]: "MCU Admin",
  [ROLE_SHORT_CODE.MCU_EMPLOYEE]: "MCU Employee",
  [ROLE_SHORT_CODE.VCU_ADMIN]: "VCU Admin",
  [ROLE_SHORT_CODE.VCU_EMPLOYEE]: "VCU Employee",
  [ROLE_SHORT_CODE.GPS_ADMIN]: "GPS Admin",
  [ROLE_SHORT_CODE.GPS_EMPLOYEE]: "GPS Employee",
  [ROLE_SHORT_CODE.OBC_ADMIN]: "OBC Admin",
  [ROLE_SHORT_CODE.OBC_EMPLOYEE]: "OBC Employee",
  [ROLE_SHORT_CODE.DCDC_ADMIN]: "DCDC Admin",
  [ROLE_SHORT_CODE.DCDC_EMPLOYEE]: "DCDC Employee",
  [ROLE_SHORT_CODE.BTMS_ADMIN]: "BTMS Admin",
  [ROLE_SHORT_CODE.BTMS_EMPLOYEE]: "BTMS Employee",
  [ROLE_SHORT_CODE.TPMS_ADMIN]: "TPMS Admin",
  [ROLE_SHORT_CODE.TPMS_EMPLOYEE]: "TPMS Employee",
};

export enum NOTIFICATION_COMPONENT_TYPE {
  LOW = 3,
  MEDIUM = 1,
  HIGH = 2,
  CRITICAL = 4,
}

export enum PERMISSION_ACTIONS {
  READ = 1,
  ADD = 2,
  UPDATE = 4,
  DELETE = 8,
  ASSIGN = 16,
  UNASSIGN = 32,
  CLONE = 64,
  EXPORT = 128,
  IMPORT = 256,
  RESYNC = 512,
  COPY_LINK = 1024,
  RESET = 2048,
  CONFIGURE = 4096,
  CHANGE_STATUS = 8192,
}

export const permissionActions: Record<string, any> = {
  List: 1,
  Add: 2,
  Update: 4,
  Delete: 8,
  Assign: 16,
  Unassign: 32,
  Clone: 64,
  Export: 128,
  Import: 256,
  Resync: 512,
  "Copy Link": 1024,
  Reset: 2048,
  Configure: 4096,
  "Change Status": 8192,
};

export const permissionObj: Record<string, any> = {
  // "/dashboard": [
  //   "db",
  //   "BMS.db",
  //   "MCU.db",
  //   "VCU.db",
  //   "sc.db",
  //   "fleet.db",
  //   "eo.db",
  //   "fleet.driver.db",
  //   "su.db",
  // ],
  "/dashboard": "db",
  "/vehicle-management": "vh.l",
  "/vehicle/[id]": "vh.l.d",
  "/models/configuration": ["vh.m", "BMS.m", "MCU.m", "VCU.m"],
  "/tickets": "t",
  "/ev-owner-management": "eo.l",
  // "/employees": "su",
  "/user-management": ["su", "su.RP", "su.DG"],
  "/fleet-management": "fleet.l",
  "/dealer-management": "sc.l",
  "/access-management": "ac.l",
  "/component-management": "c",
  "/settings": "s",
  "/rule-management": "rule.l",
  "/command-history": "cmd",
  "/notification-history": "rule.h",
  "/bms/master-management": "BMS.l",
  "/bms/details/[id]": "BMS.l.d",
  "/bms/battery-pack-history/[id]": "BMS.l.d.ov.ch",
  "/mcu/master-management": "MCU.l",
  "/mcu/details/[id]": "MCU.l.d",
  "/vcu/master-management": "VCU.l",
  "/vcu/details/[id]": "VCU.l.d",
  "/analysis": "a",
  "/carbon-emission": "ce",
  "/audit": "ah",
  "/profile": "mp",
  "/404": "404",
  "/model-management/ev": "vh.m",
  "/model-management/bms": "BMS.m",
  "/model-management/mcu": "MCU.m",
  "/model-management/vcu": "VCU.m",
  "/geo-fencing": "gr",
  "/geo-fencing/write": "gr",
  "/firmware-release": "FOTA",
  "/firmware-update-history": "FOTA.h",
  "/hardware-log": "hl",
  "/driver/dashboard": "fleet.driver.db",
  "/driver/tickets": "t",
  "/ev-owner/dashboard": "eo.db",
  "/ev-owner/tickets": "t",
  "/fleet/dashboard": "fleet.db",
  "/fleet/driver-management": "fleet.driver",
  // "/fleet/employees": "su",
  "/fleet/vehicle-management": "vh.l",
  "/fleet/vehicle/[id]": "vh.l.d",
  // "/dealer/dashboard": "Dashboard",
  // "/service-center/dashboard": "Dashboard",
  // "/dealer/employees": "su",
  "/dealer/fleet-management": "fleet.l",
  "/dealer/ev-owner-management": "eo.l",
  "/dealer/vehicle-management": "vh.l",
  "/bms/dashboard": "BMS.db",
  "/mcu/dashboard": "MCU.db",
  "/vcu/dashboard": "VCU.db",
  // "/gps/dashboard": "Dashboard",
  // "/obc/dashboard": "Dashboard",
  // "/dcdc/dashboard": "Dashboard",
  // "/btms/dashboard": "Dashboard",
  // "/tpms/dashboard": "Dashboard",
  "/employee/tickets": "t",
  // "/role": "su.RP",
  "/write-role": ["su.RP", "su"],
  // "/group": "su.DG",
  "/write-group": ["su.DG", "su"],
};

export const API_PERMISSION_PATHS = {
  OEM: {
    LIST: "c.p",
    ADD: "c.p",
    UPDATE: "c.p",
    DELETE: "c.p",
  },
  PROFILE: {
    GET: "mp.p",
    UPDATE: "mp.d",
    CHANGE_PASSWORD: {
      UPDATE: "mp.cp",
    },
    SETTING: {
      UPDATE: "mp.s",
    },
  },
  VEHICLE: {
    LIST: "vh.l.p",
    ADD: "vh.l.p",
    UPDATE: "vh.l.p",
    DELETE: "vh.l.p",
    ASSIGN: "vh.l.p",
    IMPORT: "vh.l.p",
    RESET: "vh.l.p",
    GEOFENCE: "vh.l.p",
    TRIP: {
      LIST: "vh.l.d.ta.p",
      RESYNC: "vh.l.d.ta.p",
    },
    DASHBOARD: {
      INFO: "db.info",
      FAULT_SUMMARY: "db.fs",
      TICKET: "db.t",
      FAULT: "db.raf",
      FOOOTER_CARDS: "db.f",
      MAP: "db.rtmv",
      NOTIFICATION: "db.n",
    },
    DETAILS: {
      OVERVIEW_TAB: "vh.l.d.p",
      TRIP_ANALYSIS_TAB: "vh.l.d.ta.p",
      NOTIFICATION_TAB: "vh.l.d.n.p",
      COMMAND_TAB: "vh.l.d.cmd.p",
      FAULT_TAB: "vh.l.d.f.p",
      ANALYSIS_TAB: "vh.l.d.a.p",
      ATTRIBUTE_TAB: "vh.l.d.attr.p",
      OVER_ALL_INFO: "vh.l.d.ov.oai",
      RECENT_RIPS: "vh.l.d.ov.rt",
      FAULT: "vh.l.d.ov.f",
      STATESTIC: "vh.l.d.ov.s",
      NOTIFICATION: "vh.l.d.ov.n",
      LOCATION_TAB: "vh.l.d.ov.la",
    },
    MODEL: {
      LIST: "vh.m.p",
      ADD: "vh.m.p",
      UPDATE: "vh.m.p",
      DELETE: "vh.m.p",
      COMPONENT_LIST: "vh.m.p",
      COMMAND: {
        LIST: "vh.m.p",
      },
      TELEMETRY: {
        LIST: "vh.m.p",
        UPDATE: "vh.m.p",
      },
      FAULT: {
        LIST: "vh.m.p",
        ADD: "vh.m.p",
        UPDATE: "vh.m.p",
        DELETE: "vh.m.p",
      },
    },
  },
  BMS: {
    LIST: "BMS.l.p",
    ADD: "BMS.l.p",
    UPDATE: "BMS.l.p",
    DELETE: "BMS.l.p",
    RESET: "BMS.l.p",
    CYCLE: {
      LIST: "BMS.l.d.ca.p",
      RESYNC: "BMS.l.d.ca.p",
    },
    ANALYSIS: {
      LIST: "BMS.l.d.a.p",
      EXPORT: "BMS.l.d.a.p",
    },
    DETAILS: {
      OVERVIEW_TAB: "BMS.l.d.p",
      CYCLE_ANALYSIS_TAB: "BMS.l.d.ca.p",
      NOTIFICATION_TAB: "BMS.l.d.n.p",
      COMMAND_TAB: "BMS.l.d.cmd.p",
      FAULT_TAB: "BMS.l.d.f.p",
      ANALYSIS_TAB: "BMS.l.d.a.p",
      ATTRIBUTE_TAB: "BMS.l.d.attr.p",
      OVER_ALL_INFO: "BMS.l.d.ov.oai",
      STATESTICS: "BMS.l.d.ov.s",
      FAULT: "BMS.l.d.ov.f",
      CELL_INFO: "BMS.l.d.ov.bp",
      CELL_HISTORY: "BMS.l.d.ov.ch.p",
      NOTIFICATION: "BMS.l.d.ov.n",
      LOCATION_TAB: "BMS.l.d.ov.la",
    },
    DASHBOARD: {
      READ: "BMS.db.p",
      BATTERY_STATE: "BMS.db.bs",
      FAULT: "BMS.db.af",
      SOC_ANALISYS: "BMS.db.asoc",
      STATESTIC: "BMS.db.s",
      MAP: "BMS.db.map",
      NOTIFICATION: "BMS.db.n",
    },
    MODEL: {
      LIST: "BMS.m.p",
      ADD: "BMS.m.p",
      UPDATE: "BMS.m.p",
      DELETE: "BMS.m.p",
      COMPONENT_LIST: "BMS.m.p",
      COMMAND: {
        LIST: "BMS.m.p",
      },
      TELEMETRY: {
        LIST: "BMS.m.p",
        UPDATE: "BMS.m.p",
      },
      FAULT: {
        LIST: "BMS.m.p",
        ADD: "BMS.m.p",
        UPDATE: "BMS.m.p",
        DELETE: "BMS.m.p",
      },
    },
  },
  MCU: {
    LIST: "MCU.l.p",
    ADD: "MCU.l.p",
    UPDATE: "MCU.l.p",
    DELETE: "MCU.l.p",
    CLOUD_RESET: "MCU.l.p",
    RESET: "MCU.l.p",
    ANALYSIS: {
      EXPORT: "MCU.l.d.a.p",
    },
    DETAILS: {
      OVERVIEW_TAB: "MCU.l.d.p",
      NOTIFICATION_TAB: "MCU.l.d.n.p",
      COMMAND_TAB: "MCU.l.d.cmd.p",
      FAULT_TAB: "MCU.l.d.f.p",
      ANALYSIS_TAB: "MCU.l.d.a.p",
      ATTRIBUTE_TAB: "MCU.l.d.attr.p",
      OVER_ALL_INFO: "MCU.l.d.ov.oai",
      HEALTH: "MCU.l.d.ov.oh",
      STATESTICS: "MCU.l.d.ov.s",
      FAULT: "MCU.l.d.ov.f",
      NOTIFICATION: "MCU.l.d.ov.n",
      LOCATION_TAB: "MCU.l.d.ov.la",
    },
    DASHBOARD: {
      FAULT: "MCU.db.f",
      ACTIVE_FAULT: "MCU.db.raf",
      STATESTIC: "MCU.db.st",
      NOTIFICATION: "MCU.db.n",
    },
    MODEL: {
      LIST: "MCU.m.p",
      ADD: "MCU.m.p",
      UPDATE: "MCU.m.p",
      DELETE: "MCU.m.p",
      COMPONENT_LIST: "MCU.m.p",
      COMMAND: {
        LIST: "MCU.m.p",
      },
      TELEMETRY: {
        LIST: "MCU.m.p",
        UPDATE: "MCU.m.p",
      },
      FAULT: {
        LIST: "MCU.m.p",
        ADD: "MCU.m.p",
        UPDATE: "MCU.m.p",
        DELETE: "MCU.m.p",
      },
    },
  },
  VCU: {
    LIST: "VCU.l.p",
    ADD: "VCU.l.p",
    UPDATE: "VCU.l.p",
    DELETE: "VCU.l.p",
    RESET: "VCU.l.p",
    ANALYSIS: {
      EXPORT: "VCU.l.d.a.p",
    },
    MODEL: {
      LIST: "VCU.m.p",
      ADD: "VCU.m.p",
      UPDATE: "VCU.m.p",
      DELETE: "VCU.m.p",
      COMPONENT_LIST: "VCU.m.p",
      COMMAND: {
        LIST: "VCU.m.p",
      },
      TELEMETRY: {
        LIST: "VCU.m.p",
        UPDATE: "VCU.m.p",
      },
      FAULT: {
        LIST: "VCU.m.p",
        ADD: "VCU.m.p",
        UPDATE: "VCU.m.p",
        DELETE: "VCU.m.p",
      },
    },
  },
  COMPONENT_ACCESS: {
    LIST: "ac.l.p",
    UPDATE: "ac.l.p",
  },
  ANALYSIS: {
    LIST: "a.p",
    BMS: "a.bt",
    MCU: "a.mt",
    VCU: "a.vt",
  },
  CARBON_EMISSION: {
    LIST: "ce.p",
  },
  FLEET: {
    LIST: "fleet.l.p",
    ADD: "fleet.l.p",
    UPDATE: "fleet.l.p",
    DELETE: "fleet.l.p",
    DASHBOARD: {
      // INFO: "vh.db.info",
      // FAULT_SUMMARY: "vh.db.fs",
      // TICKET: "vh.db.t",
      // FAULT: "vh.db.f",
      MAP: "fleet.db.map",
    },
    DRIVER: {
      LIST: "fleet.driver.p",
      ADD: "fleet.driver.p",
      UPDATE: "fleet.driver.p",
      DELETE: "fleet.driver.p",
    },
  },
  SERVICE_CENTER: {
    LIST: "sc.l.p",
    ADD: "sc.l.p",
    UPDATE: "sc.l.p",
    DELETE: "sc.l.p",
  },
  EV_OWNER: {
    LIST: "eo.l.p",
    ADD: "eo.l.p",
    UPDATE: "eo.l.p",
    DELETE: "eo.l.p",
    DASHBOARD: {
      MAIN: "eo.db.p",
      FAULT: "vh.db.f",
    },
  },
  SUPER_USER: {
    LIST: "su.p",
    ADD: "su.p",
    UPDATE: "su.p",
    DELETE: "su.p",
  },
  RULE: {
    TELEMETRY: {
      LIST: "rule.l.p",
      ADD: "rule.l.p",
      UPDATE: "rule.l.p",
      DELETE: "rule.l.p",
      DELIVERY_METHOD: "rule.l.dm.mdt",
    },
    GEOFENCE: {
      LIST: "gr.p",
      ADD: "gr.p",
      UPDATE: "gr.p",
      DELETE: "gr.p",
    },
    HISTORY: "rule.h.p",
  },
  SETTING: {
    LIST: "s.p",
    UPDATE: "s.p",
    DELETE: "s.p",
  },
  PRODUCT: {
    LIST: "itcu.p",
  },
  AUDIT: {
    LIST: "ah.p",
  },
  TICKET: {
    LIST: "t.p",
    ADD: "t.p",
    UPDATE: "t.p",
    DELETE: "t.p",
  },
  FOTA: {
    LIST: "FOTA.p",
    OTA: "FOTA.p",
    HISTORY: "FOTA.h.p",
  },
  COMMAND: {
    HISTORY_LIST: "cmd.p",
    SEND_COMMAND: "cmd.sc",
  },
  ROLE_PERMISSION: {
    LIST: "su.RP.p",
    ADD: "su.RP.p",
    UPDATE: "su.RP.p",
    DELETE: "su.RP.p",
    CLONE: "su.RP.p",
  },
  DEVICE_GROUP: {
    LIST: "su.DG.p",
    ADD: "su.DG.p",
    UPDATE: "su.DG.p",
    DELETE: "su.DG.p",
    CLONE: "su.DG.p",
    EV: "su.DG.EV",
    BMS: "su.DG.BMS",
    MCU: "su.DG.MCU",
    VCU: "su.DG.VCU",
  },
};
