import { createSlice } from "@reduxjs/toolkit";

export interface IMasterState {
  data: any;
  dataObj: {
    start_date?: Date;
    end_date?: Date;
  };
  vehicleUid: any;
  lastUpdated: any;
  isLive: any;
  commandAcknowledge: any;
}

const initialState: IMasterState = {
  data: {},
  dataObj: {},
  vehicleUid: undefined,
  lastUpdated: undefined,
  isLive: undefined,
  commandAcknowledge: undefined,
};

const VehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    setVehicleData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setDateRange: (state, action) => ({
      ...state,
      dataObj: action.payload,
    }),
    setVehicleListSelect: (state, action) => ({
      ...state,
      vehicleUid: action.payload,
    }),
    setLastUpdated: (state, action) => ({
      ...state,
      lastUpdated: action.payload,
    }),
    setIsLive: (state, action) => ({
      ...state,
      isLive: action.payload,
    }),
    setCommandAcknowledge: (state, action) => ({
      ...state,
      commandAcknowledge: action.payload,
    }),
  },
});

export const {
  setVehicleData,
  setDateRange,
  setVehicleListSelect,
  setLastUpdated,
  setIsLive,
  setCommandAcknowledge,
} = VehicleSlice.actions;
export default VehicleSlice;
