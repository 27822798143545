import { createSlice } from "@reduxjs/toolkit";
import {
  MODEL_CONFIGURATION_STEP_ID,
  MODEL_CONFIG_TABS,
  MODEL_MASTER_DATA_TABS,
} from "../../utils/status";

export interface IMasterState {
  vehicleDetailsTabs: Record<string, any>[];
  bmsDetailsTabs: Record<string, any>[];
  mcuDetailsTabs: Record<string, any>[];
  modelConfigTabs: Record<string, any>[];
  modelMasterDataTabs: Record<string, any>;
  vehicle_uid: string;
  ticket_state_filter: any;
  fault_data: any;
  device_status_filter: any;
  onboard_filter: any;
  model_page_component: string;
  vehicle_Over_All_Info: Record<string, any> | undefined;
  bms_Over_All_Info: Record<string, any> | undefined;
  mcu_Over_All_Info: Record<string, any> | undefined;
}

const initialState: IMasterState = {
  vehicleDetailsTabs: [],
  bmsDetailsTabs: [
    {
      name: "Overview",
      id: "bms_overview",
      href: "#",
      current: true,
    },
    {
      name: "Fault & Alerts",
      id: "bms_faults",
      href: "#",
      current: false,
    },
    {
      name: "Analysis",
      id: "bms_analysis",
      href: "#",
      current: false,
    },
    {
      name: "Attributes",
      id: "bms_attributes",
      href: "#",
      current: false,
    },
  ],
  mcuDetailsTabs: [],
  modelConfigTabs: [
    {
      name: "Information",
      id: MODEL_CONFIG_TABS.INFORMATION,
      step: 1,
      step_id: MODEL_CONFIGURATION_STEP_ID.INFORMATION,
      current: true,
    },
    {
      name: "Master Data",
      id: MODEL_CONFIG_TABS.MASTER_DATA,
      step: 2,
      step_id: null,
      current: false,
    },
    {
      name: "Commands",
      id: MODEL_CONFIG_TABS.COMMANDS,
      step: 3,
      step_id: MODEL_CONFIGURATION_STEP_ID.COMMANDS,
      current: false,
    },
    {
      name: "Faults",
      id: MODEL_CONFIG_TABS.FAULTS,
      step: 4,
      step_id: MODEL_CONFIGURATION_STEP_ID.FAULTS,
      current: false,
    },
    {
      name: "Configurations",
      id: MODEL_CONFIG_TABS.CONFIGURATION,
      step: 5,
      step_id: MODEL_CONFIGURATION_STEP_ID.CONFIGURATION,
      current: false,
    },
    {
      name: "Telemetry",
      id: MODEL_CONFIG_TABS.TELEMETRY,
      step: 6,
      step_id: MODEL_CONFIGURATION_STEP_ID.TELEMETRY,
      current: false,
    },
  ],
  modelMasterDataTabs: {
    VCU: [
      {
        name: "Vehicle State",
        id: MODEL_MASTER_DATA_TABS.VCU_VEHICLE_STATE,
        step: 1,
        step_id: MODEL_CONFIGURATION_STEP_ID.VCU_VEHICLE_STATE,
        current: true,
      },
      {
        name: "Fault Severity",
        id: MODEL_MASTER_DATA_TABS.VCU_FAULT_SEVERITY,
        step: 2,
        step_id: MODEL_CONFIGURATION_STEP_ID.VCU_FAULT_SEVERITY,
        current: false,
      },
      {
        name: "Drive Mode",
        id: MODEL_MASTER_DATA_TABS.VCU_DRIVE_MODE,
        step: 3,
        step_id: MODEL_CONFIGURATION_STEP_ID.VCU_DRIVE_MODE,
        current: false,
      },
    ],
    BMS: [
      {
        name: "BMS State",
        id: MODEL_MASTER_DATA_TABS.BMS_STATE,
        step: 1,
        step_id: MODEL_CONFIGURATION_STEP_ID.BMS_STATE,
        current: true,
      },
    ],
    MCU: [
      {
        name: "MCU State",
        id: MODEL_MASTER_DATA_TABS.MCU_STATE,
        step: 1,
        step_id: MODEL_CONFIGURATION_STEP_ID.MCU_STATE,
        current: true,
      },
    ],
  },
  vehicle_uid: "",
  ticket_state_filter: undefined,
  fault_data: undefined,
  device_status_filter: undefined,
  onboard_filter: undefined,
  model_page_component: "",
  vehicle_Over_All_Info: undefined,
  bms_Over_All_Info: undefined,
  mcu_Over_All_Info: undefined,
};

const DetailsTabsSlice = createSlice({
  name: "detailsTab",
  initialState,
  reducers: {
    setBMSDetailsActiveTab: (state, action) => ({
      ...state,
      bmsDetailsTabs: action.payload,
    }),
    setVehicleUID: (state, action) => ({
      ...state,
      vehicle_uid: action.payload,
    }),
    setTicketStateFilter: (state, action) => ({
      ...state,
      ticket_state_filter: action.payload,
      fault_data: undefined,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setSelectedFaultData: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: action.payload,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setDeviceStatusFilter: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      device_status_filter: action.payload,
      onboard_filter: undefined,
    }),
    setDeviceOnboardFilter: (state, action) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      device_status_filter: undefined,
      onboard_filter: action.payload,
    }),
    setClearAllFilter: (state) => ({
      ...state,
      ticket_state_filter: undefined,
      fault_data: undefined,
      device_status_filter: undefined,
      onboard_filter: undefined,
    }),
    setModelConfigActiveTab: (state, action) => ({
      ...state,
      modelConfigTabs: state.modelConfigTabs.map((tab) => ({
        ...tab,
        current: tab.id === action.payload,
      })),
    }),
    setCurrentModelPage: (state, action) => ({
      ...state,
      model_page_component: action.payload,
    }),
    setCurrentModelMasterDataPage: (state, action) => ({
      ...state,
      modelMasterDataTabs: {
        ...state.modelMasterDataTabs,
        [action.payload.component]: state.modelMasterDataTabs[action.payload.component].map(
          (tab: any) => ({
            ...tab,
            current: tab.id === action.payload.id,
          }),
        ),
      },
    }),
    setVehicleOverAllInfo: (state, action) => ({
      ...state,
      vehicle_Over_All_Info: action.payload,
      bms_Over_All_Info: undefined,
      mcu_Over_All_Info: undefined,
    }),
    setBMSOverAllInfo: (state, action) => ({
      ...state,
      vehicle_Over_All_Info: undefined,
      bms_Over_All_Info: action.payload,
      mcu_Over_All_Info: undefined,
    }),
    setMCUOverAllInfo: (state, action) => ({
      ...state,
      vehicle_Over_All_Info: undefined,
      bms_Over_All_Info: undefined,
      mcu_Over_All_Info: action.payload,
    }),
  },
});

export const {
  setBMSDetailsActiveTab,
  setVehicleUID,
  setTicketStateFilter,
  setSelectedFaultData,
  setDeviceStatusFilter,
  setClearAllFilter,
  setDeviceOnboardFilter,
  setModelConfigActiveTab,
  setCurrentModelPage,
  setCurrentModelMasterDataPage,
  setVehicleOverAllInfo,
  setBMSOverAllInfo,
  setMCUOverAllInfo,
} = DetailsTabsSlice.actions;
export default DetailsTabsSlice;
