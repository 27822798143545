import type { AppProps } from "next/app";
import Head from "next/head";
import "react-perfect-scrollbar/dist/css/styles.css";
import { QueryClient, QueryClientProvider, setLogger } from "react-query";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useRouter } from "next/router";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
// import ReactTooltip from "react-tooltip";
import "tailwindcss/tailwind.css";
import "../styles/globals.scss";
import "swiper/css";
import "swiper/css/pagination";
// import Gleap from "gleap";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { persistor, store } from "../Store/Store";
import "react-loading-skeleton/dist/skeleton.css";
import { setHistoryRoute } from "../Store/Slices/MasterSlice";

setLogger({
  log: () => {},
  warn: () => {},
  error: () => {},
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

if (process.env.NEXT_PUBLIC_SENTRY_CHECK === "true") {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  });
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    // add current route in history array
    const handleRouteChange = (url: string) => {
      store.dispatch(setHistoryRoute(url));
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    // Gleap.initialize(process.env.NEXT_PUBLIC_GLEAP_KEY || "");
  }, []);

  const clarityData = `(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "j2ot9mq4mo");`;

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Head>
              <title>Intuions</title>
              <meta name="viewport" content="initial-scale=1.0, width=device-width" />

              {process.env.NEXT_PUBLIC_CLARITY_CHECK === "true" && (
                <script defer async dangerouslySetInnerHTML={{ __html: clarityData }} />
              )}
            </Head>
            <Component {...pageProps} />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            {/* <ReactTooltip id="mainTooltip" aria-haspopup="true" effect="solid" globalEventOff="click" /> */}
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
